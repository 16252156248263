import { RISK_SECTION_HIGH, RISK_SECTION_LOW, RISK_SECTION_MEDIUM, RISK_SECTION_VERY_HIGH } from '@consts/risk';
import {
  formatBMIQuestion,
  formatOption,
  formatQuestion,
  formatResult,
  formatRisk,
  formatYesNoQuestion,
} from '@/src/common/helpers/quiz';
import { COL_SIZE_FULL, QUESTION_TYPE_GENDER } from '@consts/quiz';

export const riskSettings = [
  formatRisk(RISK_SECTION_LOW, 'common.low', 'risk.low', 0, 2),
  formatRisk(RISK_SECTION_MEDIUM, 'common.medium', 'risk.medium', 2, 4.5),
  formatRisk(RISK_SECTION_HIGH, 'common.high', 'risk.high', 4.5, 6.5),
  formatRisk(RISK_SECTION_VERY_HIGH, 'common.very_high', 'risk.very_high', 6.5, 99999),
];

export const resultSettings = [
  formatResult(
    'En traitant au plus tôt le problème, vous pourrez récupérer plus rapidement et réduire tout risque de développer des problèmes plus importants. Avec Joint Academy, vous pouvez dès maintenant obtenir une évaluation et un traitement adapté.',
    'D’après vos réponses, vos douleurs dans la hanche vous causent une légère gêne dans votre vie quotidienne. Toutefois, il est toujours bien de faire évaluer votre douleur dans les temps. À un stade précoce, le développement des douleurs articulaires peut être ralenti et on peut réduire de manière proactive le risque de problèmes sur le long terme. Traiter le problème rapidement vous donnera aussi les meilleures chances de profiter d’une vie active et sans douleur. Nous vous conseillons donc de vous faire aider pour évaluer ce problème et obtenir un traitement.<br/><br/>Aujourd’hui, le traitement de base pour les douleurs de la hanche combine des exercices adaptés et une éducation thérapeutique vous permettant de mieux comprendre vos douleurs. C’est le traitement qui est identifié par la recherche comme offrant les meilleurs résultats et étant le plus efficace pour soulager la douleur, et c’est aussi celui qui est recommandé par les directives internationales.<br/><br/>Avec Joint Academy, vous recevez un traitement complet et cliniquement prouvé pour votre hanche, depuis votre téléphone. Vous pourrez vous sentir mieux, jour après jour, depuis le confort de votre domicile ! Au début de votre traitement, vous serez affecté à un kinésithérapeute qui vous soutiendra et sera disponible à votre demande tout au long du traitement. Il ou elle vous aidera à mettre en place un programme d’exercice sur-mesure pour vous aider à réduire vos douleurs dans la hanche et retrouver votre mobilité. Ces exercices ne prennent que 5 minutes par jour.<br/><br/>2 patients sur 3 ressentent un effet positif après un mois de traitement seulement !',
    0,
    2,
  ),
  formatResult(
    'Mais pas d’inquiétude ! En traitant vos douleurs de hanche à temps, vous réduirez le risque de développer des problèmes sur le long terme. Avec Joint Academy, vous pouvez dès maintenant obtenir un traitement adapté.',
    'D’après vos réponses, vos douleurs dans la hanche vous causent une gêne modérée et semblent avoir un impact négatif dans votre quotidien. Avec des soins adaptés, vous pouvez vous sentir mieux et faire à nouveau les activités que vous aimez. Démarrer votre traitement à un stade précoce permettra de ne pas mettre plus de tension sur vos articulations et de réduire vos douleurs plus rapidement. Nous vous conseillons donc de vous faire aider pour évaluer ce problème et obtenir un traitement.<br/><br/>Aujourd’hui, le traitement de base pour les douleurs de la hanche combine des exercices adaptés et une éducation thérapeutique vous permettant de mieux comprendre vos douleurs. C’est le traitement qui est identifié par la recherche comme offrant les meilleurs résultats et étant le plus efficace pour soulager la douleur, et c’est aussi celui qui est recommandé par les directives internationales.<br/><br/>Avec Joint Academy, vous recevez un traitement complet et cliniquement prouvé pour votre hanche, depuis votre téléphone. Vous pourrez vous sentir mieux, jour après jour, depuis le confort de votre domicile ! Au début de votre traitement, vous serez affecté à un kinésithérapeute qui vous soutiendra et sera disponible à votre demande tout au long du traitement. Il ou elle vous aidera à mettre en place un programme d’exercice sur-mesure pour vous aider à réduire vos douleurs dans la hanche et retrouver votre mobilité. Ces exercices ne prennent que 5 minutes par jour.<br/><br/>2 patients sur 3 ressentent un effet positif après un mois de traitement seulement !',
    2,
    4.5,
  ),
  formatResult(
    'Mais pas d’inquiétude ! Vous pouvez traiter vos douleurs dans la hanche pour profiter à nouveau des activités que vous aimez. Avec Joint Academy, vous pouvez dès maintenant obtenir un traitement adapté.',
    'D’après vos réponses, vous êtes fortement gêné par votre douleur dans la hanche et vous avez un risque élevé de développer des problèmes sur le long terme. Avec des soins adaptés, vous pouvez vous sentir mieux et vivre à nouveau avec moins de douleurs et plus de mobilité. Nous vous conseillons donc de vous faire aider pour évaluer ce problème et obtenir un traitement.<br/><br/>Aujourd’hui, le traitement de base pour les douleurs de la hanche combine des exercices adaptés et une éducation thérapeutique vous permettant de mieux comprendre vos douleurs. C’est le traitement qui est identifié par la recherche comme offrant les meilleurs résultats et étant le plus efficace pour soulager la douleur, et c’est aussi celui qui est recommandé par les directives internationales.<br/><br/>Avec Joint Academy, vous recevez un traitement complet et cliniquement prouvé pour votre hanche, depuis votre téléphone. Vous pourrez vous sentir mieux, jour après jour, depuis le confort de votre domicile ! Au début de votre traitement, vous serez affecté à un kinésithérapeute qui vous soutiendra et sera disponible à votre demande tout au long du traitement. Il ou elle vous aidera à mettre en place un programme d’exercice sur-mesure pour vous aider à réduire vos douleurs dans la hanche et retrouver votre mobilité. Ces exercices ne prennent que 5 minutes par jour.<br/><br/>2 patients sur 3 ressentent un effet positif après un mois de traitement seulement !',
    4.5,
    99999,
  ),
];

export const questions = [
  formatYesNoQuestion(
    'question1',
    'Au cours du dernier mois, avez-vous eu mal à la hanche pendant un mouvement ou un effort ?',
    2,
  ),
  formatBMIQuestion('question2'),
  formatQuestion(
    'question3',
    'Vous arrive-t-il de ressentir une raideur le matin qui dure jusqu’à 30-60 min ?',
    [
      formatOption('common.yes', 1, 0, COL_SIZE_FULL, COL_SIZE_FULL),
      formatOption('Non, je n’ai pas de raideur', 0, 0, COL_SIZE_FULL, COL_SIZE_FULL),
      formatOption('Non, la raideur ne disparaît pas', 0, 0, COL_SIZE_FULL, COL_SIZE_FULL),
    ],
  ),
  formatQuestion(
    'question4',
    'Quel est votre genre ?',
    [
      formatOption('Féminin', 1, 0, COL_SIZE_FULL, COL_SIZE_FULL, 'woman'),
      formatOption('Masculin', 0, 0, COL_SIZE_FULL, COL_SIZE_FULL, 'man'),
      formatOption('Autre', 0, 0, COL_SIZE_FULL, COL_SIZE_FULL, 'other'),
    ],
    QUESTION_TYPE_GENDER,
  ),
  formatYesNoQuestion(
    'question5',
    'Avez-vous, ou avez-vous eu, un métier qui implique de vous tenir debout, de marcher ou d’effectuer des mouvements de rotation ?',
  ),
  formatYesNoQuestion(
    'question6',
    'Avez-vous une luxation congénitale de la hanche (dysplasie) ?',
    2,
  ),
];

export const references = [
  'American College of Rheumatology. Criteria [Internet]. Atlanta: American College of Rheumatology [cited 2021 June 16] From: <a href="https://www.rheumatology.org/Practice-Quality/Clinical-Support/Criteria">https://www.rheumatology.org/Practice-Quality/Clinical-Support/Criteria</a>',
  'Altman R, Alarcón G, Appelrouth D, et al. The American College of Rheumatology criteria for the classification and reporting of osteoarthritis of the hip. Arthritis Rheum. 1991;34(5):505-514. doi:10.1002/art.1780340502',
  'Saberi Hosnijeh F, Kavousi M, Boer CG, et al. Development of a prediction model for future risk of radiographic hip osteoarthritis. Osteoarthritis Cartilage. 2018;26(4):540-546. doi:10.1016/j.joca.2018.01.015',
  'Altman R, Asch E, Bloch D, et al. Development of criteria for the classification and reporting of osteoarthritis. Classification of osteoarthritis of the knee. Diagnostic and Therapeutic Criteria Committee of the American Rheumatism Association. Arthritis Rheum. 1986;29(8):1039-1049. doi:10.1002/art.1780290816',
  'Zheng H, Chen C. Body mass index and risk of knee osteoarthritis: systematic review and meta-analysis of prospective studies. BMJ Open. 2015 Dec 11;5(12):e007568. doi: 10.1136/bmjopen-2014-007568. PMID: 26656979; PMCID: PMC4679914.',
  'Felson et al, Arthr. and Rheum, 1997Felson DT, Zhang Y, Hannan MT, et al. Risk factors for incident radiographic knee osteoarthritis in the elderly: the Framingham Study. Arthritis Rheum. 1997;40(4):728-733. doi:10.1002/art.1780400420',
  'Blagojevic M, Jinks C, Jeffery A, Jordan KP. Risk factors for onset of osteoarthritis of the knee in older adults: a systematic review and meta-analysis. Osteoarthritis Cartilage. 2010;18(1):24-33. doi:10.1016/j.joca.2009.08.010',
];
